.product-card__container .popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.popup-content.view-product-popover-content-content {
  width: 150px !important;
  height: 3rem;
}

.product-card__container .popup-content .popup-arrow {
  display: none;
}

.product-card__container img {
  max-height: 170px;
}

.product-card__image-container {
  height: 170px;
}

.product-card__container .ant-card-body {
  width: 100%;
  min-height: 355px;
}

.product-card__container .product-card__price {
  background-color: #1890ff;
}
