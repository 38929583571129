@import '~antd/dist/antd.css';

@font-face {
  font-family: AvenirMedium;
  src: url('./assets/fonts/AvenirLTPro-Medium.woff') format('woff'),
    url('./assets/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
    url('./assets/fonts/AvenirLTPro-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: SonoraMedium;
  src: url('./assets/fonts/SonoraProOT-Medium.woff') format('woff'),
    url('./assets/fonts/SonoraProOT-Medium.woff2') format('woff2'),
    url('./assets/fonts/SonoraProOT-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

html {
  background-color: #edf2f7;
}

body {
  font-family: AvenirMedium system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.mn-bg-black {
  background-color: #202020;
}
.mn-bg-yellow {
  background-color: #ebe714;
}
.mn-bg-light-red {
  background-color: #ee3b34;
}

.header-actions__refresh-all {
  border: none;
}

.footer .mn-bg-light-red {
  background-image: url('./assets/images/Patterns-Numbers-Red.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.footer .mn-bg-yellow {
  background-image: url('./assets/images/Patterns-Symbols-Yellow.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.header-actions__menu {
  min-width: 615px;
}

body {
  overflow: scroll;
}
