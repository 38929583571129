.order-product-modal .popup-content {
  width: 602px !important;
  height: 96%;
  overflow-y: auto;
}

.order-product-modal__container {
  width: 455px;
  height: 425px;
}

.order-product-modal__container .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}

#orderProductModal .ant-form-item {
  margin-bottom: 16px;
}

@media only screen and (max-width: 575px) {
  .order-product-modal__container .ant-col-offset-8 {
    margin-left: 0;
  }
}
