.popup-content {
  width: 500px !important;
  height: 630px;
  overflow-y: auto;
}

.create-product-modal__container {
  width: 455px;
  height: 425px;
}

.popup-content .product-card__container {
  height: 69%;
}

.product-card__container {
  min-height: 385px;
}
